import { atom } from "recoil";

export const toastState = atom({
    key: "toastState",
    default: {
        isToast: false,
        message: "",
    },
});

export const loadingState = atom({
    key: "loadingState",
    default: false,
});