import { React, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formSubmit, checkAll, checkedArticleDelete } from "../utils/form";
import { getAccessToken } from "../utils/common";
import axios from "axios";
import Layout from "../components/Layout";
import Pager from "../components/Pager";
import SearchForm from "../components/SearchForm";
import DndUpload from "../components/DndUpload";

const Jaelyo = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? "1";
    const q = searchParams.get("q") ?? "";

    const defaultRow = { idx: "", name1: "", filename0: "" };

    const [data, setData] = useState([]);
    const [row, setRow] = useState(defaultRow);
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        getData();
    }, [page, q]);

    const getData = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm/jaelyo?page=${page}&q=${q}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });

        if (data) {
            setData(data.list);
            setPageHelper(data.pageHelper);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await formSubmit(e, null);

        setRow(defaultRow);

        getData();

        checkAll(false);

        setSearchParams({ ...Object.fromEntries(searchParams.entries()), clear: Date.now() });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        if (await checkedArticleDelete(e, "V3_JAELYO_tbl")) {
            getData();
        }
        checkAll(false);
    };

    return (
        <Layout>
            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <div className="table-responsive rounded bg-white p-3 shadow">
                        <SearchForm />
                        <form onSubmit={handleDelete}>
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th className="text-center text-nowrap" width={50}>
                                            <input type="checkbox" onClick={(e) => checkAll(e.target.checked)} />
                                        </th>
                                        <th className="text-center text-nowrap" width={50}>
                                            Idx.
                                        </th>
                                        <th className="text-center text-nowrap">재료명</th>
                                        <th className="text-center text-nowrap">이미지</th>
                                    </tr>
                                    {data.map((row, i) => (
                                        <tr key={i}>
                                            <td className="text-center">
                                                <input type="checkbox" name="idx" value={row.idx} />
                                            </td>
                                            <td className="text-center">{row.idx}</td>
                                            <td className="">
                                                <a className="hand link-underline" onClick={() => setRow(row)}>
                                                    {row.name1}
                                                </a>
                                            </td>
                                            <td className="">{row.filename0 && <img src={row.filename0} alt="" width={22} height={22} />}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button type="submit" className="btn btn-danger btn-sm">
                                삭제
                            </button>
                        </form>
                    </div>
                    <br />
                    <Pager pageHelper={pageHelper} />
                </div>
                <div className="col-12 col-md-6">
                    <div className="rounded bg-white p-3 shadow">
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="table" defaultValue={"V3_JAELYO_tbl"} />
                            <input type="hidden" name="idx" value={row.idx} onChange={(e) => setRow({ ...row, idx: e.target.value })} />

                            <div className="d-flex flex-row mb-3">
                                <DndUpload fieldName="filename0" fileUrl={row.filename0} />

                                <div className="ms-2 d-flex flex-column flex-fill">
                                    <input
                                        name="name1"
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="재료명"
                                        value={row.name1}
                                        onChange={(e) => setRow({ ...row, name1: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        setRow(defaultRow);
                                        setSearchParams({ ...Object.fromEntries(searchParams.entries()), clear: Date.now() });
                                    }}
                                >
                                    취소
                                </button>
                                <button type="submit" className="btn btn-sm btn-primary">
                                    저장
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Jaelyo;
