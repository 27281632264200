import React, { useState, useEffect } from "react";
import ImageDropZoneDirectUpload from "../components/ImageDropZoneDirectUpload";
import MultiFileUploader from "../components/MultiFileUploader";

const RcpStep = ({ row, setRow, step, i, removeRow, setAutoGen, generatoeMemo }) => {
    return (
        <div className="bg-white p-0 border">
            <div className="d-flex justify-content-between align-items-center mx-2">
                <div className="d-flex flex-row align-items-center">
                    <h3 className="mb-0 me-4">
                        {i + 1}
                    </h3>
                    <MultiFileUploader index={i} row={row} setRow={setRow} />
                </div>
                <button type="button" className="btn btn-link text-danger pe-0" onClick={() => removeRow(i)}>
                    <i className="bi bi-dash-circle-fill"></i>
                </button>
            </div>
            <div className="d-flex flex-row overflow-x-auto mx-2">
                {new Array(10).fill().map((_, j) => (
                    <div key={`img_${j}`} style={{ width: "60px" }} className="me-1">
                        <ImageDropZoneDirectUpload
                            index={i}
                            j={j}
                            boxSize={60}
                            filename={eval(`step.filename${j}`)}
                            row={row}
                            setRow={setRow}
                        />
                        <input
                            type="text"
                            name={`filename${j}`}
                            className="form-control form-control-sm mt-1"
                            value={eval(`step.filename${j}`)}
                            onChange={(e) => {
                                const steps = [...row.steps];
                                eval(`steps[i].filename${j} = e.target.value;`);
                                setRow({ ...row, steps });
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className="d-flex flex-row mt-1">
                <div className="d-flex flex-column flex-fill">
                    <div className="d-flex flex-wrap mx-2">
                        {step.icon.map((_, j) => (
                            <div
                                key={j}
                                className="rounded-pill border bg-light d-flex flex-row align-items-center me-2 mt-2"
                                style={{ fontSize: "12px" }}
                            >
                                {step.icon[j] !== "" && (
                                    <img
                                        src={step.icon[j]}
                                        alt=""
                                        className="rounded-pill"
                                        style={{ width: "25px", height: "25px" }}
                                    />
                                )}

                                <div className="d-flex flex-row" style={{ marginTop: "3px" }}>
                                    <div className="ms-1 fw-bold">{step.name1[j]}</div>

                                    <div className="ms-1 fw-bold">{step.capacity[j]}</div>

                                    <div className="ms-1">{step.desc1[j]}</div>

                                    <div
                                        className="mx-2 hand"
                                        onClick={(e) => {
                                            const steps = [...row.steps];
                                            steps[i].icon.splice(j, 1);
                                            steps[i].name1.splice(j, 1);
                                            steps[i].capacity.splice(j, 1);
                                            steps[i].desc1.splice(j, 1);
                                            setRow({ ...row, steps });
                                        }}
                                    >
                                        <i className="bi bi-x-lg"></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="input-group mt-3">
                        <textarea
                            className="form-control form-control-sm border-top border-0 rounded-0"
                            placeholder=""
                            style={{ height: "140px" }}
                            value={step.tmp}
                            onChange={(e) => {
                                const steps = [...row.steps];
                                steps[i].tmp = e.target.value;
                                setRow({ ...row, steps });
                            }}
                        />
                        <button className="btn btn-primary rounded-0" type="button" onClick={() => setAutoGen(i)}>
                            Gen
                        </button>
                    </div>

                    <textarea
                        name="content"
                        className="form-control form-control-sm mt-0 border-top border-0 rounded-0"
                        placeholder="내용"
                        style={{ height: "80px" }}
                        value={step.content}
                        onChange={(e) => {
                            const steps = [...row.steps];
                            steps[i].content = e.target.value;
                            setRow({ ...row, steps });
                        }}
                        onBlur={(e) => {
                            setTimeout(() => {
                                generatoeMemo(e.target.value, i);
                            }, 200);
                        }}
                    />

                    <input
                        type="text"
                        name="tips"
                        className="form-control form-control-sm border-top border-0 rounded-0"
                        placeholder="tips"
                        value={step.tips}
                        onChange={(e) => {
                            const steps = [...row.steps];
                            steps[i].tips = e.target.value;
                            setRow({ ...row, steps });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default RcpStep;
