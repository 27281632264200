import React, { useState, useEffect } from "react";
import { getAccessToken } from "../utils/common";
import axios from "axios";
import { fi } from "translate-google/languages";

function MultiFileUploader({ index, row, setRow }) {
    const [loading, setLoading] = useState(false);

    /**
     * 클라우디나리 업로드 서버 거쳐서 업로드
     */
    /*
    async function handleFileChange(e) {
        setLoading(true);
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append("upload_file", file);
            const { data } = await axios({
                url: `${process.env.REACT_APP_HOST}/cloudinary`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            console.log(data);
            const fileUrl = data.url;
            const steps = [...row.steps];
            steps[index][`filename${i}`] = fileUrl;
            setRow({ ...row, steps });
        }
        setLoading(false);
    }
/*
    /**
     * 클라우디나리 업로드 다이렉트로 업로드
     */
    async function handleFileChange(e) {
        // setLoading(true);
        // const files = e.target.files;
        // for (let i = 0; i < files.length; i++) {
        //     const file = files[i];
        //     const formData = new FormData();
        //     formData.append("file", file);
        //     formData.append("upload_preset", "mzbjpjdi");
        //     const { data } = await axios({
        //         url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
        //         method: "POST",
        //         data: formData,
        //         headers: {
        //             "Content-Type": "application/x-www-form-urlencoded",
        //         },
        //     });
        //     const fileUrl = data.secure_url;
        //     const steps = [...row.steps];
        //     steps[index][`filename${i}`] = fileUrl;
        //     steps[index]['file_count'] = files.length;
        //     setRow({ ...row, steps });
        // }
        // setLoading(false);
        setLoading(true);
        const files = e.target.files;
        const uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("upload_preset", "mzbjpjdi");
            formData.append("folder", "rcp2");

            // 각 파일 업로드를 Promise로 생성하고 배열에 추가
            const uploadPromise = axios({
                url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
                method: "POST",
                data: formData,
            }).then(({ data }) => {
                const fileUrl = data.secure_url;
                const steps = [...row.steps];
                steps[index][`filename${i}`] = fileUrl;
                steps[index]["file_count"] = files.length;
                return { ...row, steps };
            });

            uploadPromises.push(uploadPromise);
        }

        // 모든 파일 업로드가 완료될 때까지 기다림
        const results = await Promise.all(uploadPromises);
        // 모든 업로드가 완료된 후 마지막 결과를 사용하여 상태를 업데이트
        setRow(results[results.length - 1]);
        setLoading(false);
    }

    /**
     * 로컬 업로드
     * /upload/local
     */
    // async function handleFileChange(e) {
    //     setLoading(true);
    //     const files = e.target.files;

    //     const formData = new FormData();

    //     for (let i = 0; i < files.length; i++) {
    //         formData.append(`filename${i}`, files[i]);
    //     }
    //     const { data } = await axios({
    //         url: `${process.env.REACT_APP_HOST}/upload/local`,
    //         method: "POST",
    //         data: formData,
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded",
    //             Authorization: `Bearer ${getAccessToken()}`,
    //         },
    //     });
    //     console.log(data);
    //     const steps = [...row.steps];

    //     Object.keys(data).forEach((key) => {
    //         steps[index][key] = data[key];
    //     });
    //     setRow({ ...row, steps });
    //     setLoading(false);
    // }

    return (
        <>
            <input id={`file${index}`} type="file" multiple onChange={handleFileChange} style={{ display: "none" }} />
            {loading ? (
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <label
                    htmlFor={`file${index}`}
                    className="d-flex align-items-center btn btn-sm btn-primary p-1"
                    style={{ fontSize: "12px", height: "24px" }}
                >
                    파일선택
                </label>
            )}
        </>
    );
}

export default MultiFileUploader;
