import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getAccessToken, replaceAll } from "../utils/common";
import { toastState, loadingState } from "../utils/atom";
import { Link } from "react-router-dom";
import axios from "axios";
import ImageDropZoneDirectUpload from "../components/ImageDropZoneDirectUpload";
import Prepare from "./Prepare";
import RcpStep from "./RcpStep";
import SingleFileUploader from "../components/SingleFileUploader";

function RcpDetail({ modalClose, idx }) {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);
    const [categorys, setCategorys] = useState([]);

    const [mainImageUrl, setMainImageUrl] = useState("");

    const defaultChildRow = {
        tmp: "",
        content: "",
        tips: "",
        filename0: "",
        filename1: "",
        filename2: "",
        filename3: "",
        filename4: "",
        filename5: "",
        filename6: "",
        filename7: "",
        filename8: "",
        filename9: "",
        icon: [],
        name1: [],
        capacity: [],
        desc1: [],
    };
    const [row, setRow] = useState({
        idx: "",
        title: "",
        soge: "\n2인분",
        filename0: "",
        category: "",
        tags: "",
        writer: "최고의요리비결",
        source: "", //"https://home.ebs.co.kr/cook/main",
        steps: [defaultChildRow],
        prepareArr: [],
        prepareText: "",
    });

    useEffect(() => {
        (async () => {
            if (idx !== "add") {
                await fetch();
            }
            await getCategorys();
        })();
    }, [idx]);

    useEffect(() => {
        setRow({
            ...row,
            filename0: mainImageUrl,
        });
    }, [mainImageUrl]);

    async function fetch() {
        const url = `${process.env.REACT_APP_HOST}/v3adm/rcp/${idx}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        if (data) {
            const json = JSON.parse(data.json_data);
            for (const step of json.steps) {
                if (!step.tmp) {
                    step.tmp = "";
                }
            }
            json.idx = data.idx;

            if (json.prepareArr === undefined) {
                json.prepareArr = [];
                json.prepareText = "";
            }
            setRow(json);
        }
    }

    async function getCategorys() {
        const url = `${process.env.REACT_APP_HOST}/v3adm/categorys`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        if (data) {
            setCategorys(data);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);

        // created, modified 제거!
        delete row.created;
        delete row.modified;

        const frm = {};

        if (row.idx === "") {
            // 다음 idx 구하기
            const { data } = await axios({
                url: `${process.env.REACT_APP_HOST}/crud/next_index/RCP_tbl`,
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            //
            row.idx = data;
        } else {
            frm.idx = row.idx;
        }

        frm.table = "V3_RCP_tbl";
        frm.title = row.title;
        frm.filename0 = row.filename0;
        frm.category = row.category;
        frm.tags = row.tags;
        frm.writer = row.writer;

        if (frm.idx) {
            console.log("frm", frm);
            // 수정 모드일때만 진입!
            var i = 0;
            for (const step of row.steps) {
                i++;
                if (step.filename0 === "") {
                    alert(`${i}번 스텝 이미지 X`);
                    scrollToElement(`step${i}`);
                    setLoading(false);
                    return;
                }

                for (var j = 0; j < step.file_count; j++) {
                    if (step[`filename${j}`] === "") {
                        alert(`${i}번 스텝 ${j + 1}번째 이미지 X`);
                        scrollToElement(`step${i}`);
                        setLoading(false);
                        return;
                    }
                }
            }
        }

        // return;
        const json_str = JSON.stringify(row);

        // 임시로 json_data 저장
        await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: {
                table: "V3_RCP_TEMP_tbl",
                idx: "1",
                json_data: json_str,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        //

        frm.json_data = json_str;

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: frm,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        setLoading(false);

        setToast({
            isToast: true,
            message: "저장 되었습니다.",
        });

        modalClose(true);
    }

    function setJsonData() {
        const tmp = prompt("", "");
        if (tmp === null || tmp.trim() === "") {
            return;
        }
        const newRow = JSON.parse(tmp);
        setRow(newRow);
    }

    function autoRecipeOrder() {
        const txts = prompt("텍스트를 입력하세요.");
        if (txts === null) {
            return;
        }
        const txtArr = [];
        for (const t of txts.split("\n")) {
            if (t !== "") {
                const tmp = t.split(". ");
                txtArr.push(tmp[1]);
            }
        }

        const steps = [];
        for (const txt of txtArr) {
            const step = { ...defaultChildRow };
            step.content = txt;
            steps.push(step);
        }
        console.log(steps);
        setRow({ ...row, steps });
    }

    function scrollToElement(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    function addRow(i, direction) {
        const steps = [...row.steps];
        if (direction === "up") {
            steps.splice(i, 0, defaultChildRow);
        } else {
            steps.splice(i + 1, 0, defaultChildRow);
        }
        setTimeout(() => {
            setRow({ ...row, steps });
        }, 300);
    }

    function removeRow(i) {
        const steps = [...row.steps];
        if (i === 0 && steps.length === 1) {
            window.alert("첫번째 행은 삭제할 수 없습니다.");
            return;
        }

        steps.splice(i, 1);
        setRow({ ...row, steps });
    }

    async function setAutoGen(i) {
        const steps = [...row.steps];
        let txt = steps[i].tmp;
        const tmpArr = txt.split(",");
        txt = "";
        var j = 0;
        for (const t of tmpArr) {
            if (j > 0) {
                txt += "\n";
            }
            txt += t.trim();
            j++;
        }
        steps[i].tmp = txt;

        const url = `${process.env.REACT_APP_HOST}/v3adm/auto_gen`;
        const { data } = await axios({
            url,
            method: "POST",
            data: {
                txt,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        if (data) {
            const icon = [];
            const name1 = [];
            const capacity = [];
            const desc1 = [];

            for (const d of data) {
                icon.push(d.icon);
                name1.push(d.name1);
                capacity.push(d.capacity);
                desc1.push(d.desc1);
            }

            steps[i].icon = icon;
            steps[i].name1 = name1;
            steps[i].capacity = capacity;
            steps[i].desc1 = desc1;
            setRow({ ...row, steps });
        }
    }

    function generatoeMemo(txt, i) {
        var tmp = replaceAll(txt, "\n", " ");
        tmp = replaceAll(tmp, "  ", " ");
        tmp = replaceAll(tmp, "   ", " ");
        tmp = replaceAll(tmp, "    ", " ");
        const steps = [...row.steps];
        steps[i].content = tmp;
        setRow({ ...row, steps });
    }

    return (
        <div className="position-absolute top-0 start-0 bg-light w-100" style={{ zIndex: "999", display: "block" }}>
            <div className="d-flex justify-content-between border-bottom py-3 mb-5 bg-white">
                <Link to="/v3/adm/jaelyo" className="ms-1" target="_blink">
                    재료등록
                </Link>
                <button type="button" className="btn-close me-1" onClick={() => modalClose(false)}></button>
            </div>
            <form onSubmit={handleSubmit} className="px-3">
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-link text-dark" onClick={() => setJsonData()}>
                        json
                    </button>

                    <button type="button" className="btn btn-link" onClick={() => autoRecipeOrder()}>
                        요리순서일괄입력
                    </button>

                    <button type="submit" className={`btn btn-${row.idx === "" ? "primary" : "success"}`}>
                        {row.idx === "" ? "Add" : "Modify"}
                    </button>
                </div>

                <input type="hidden" name="table" defaultValue={"V3_RCP_tbl"} />
                <input
                    type="hidden"
                    name="idx"
                    className="form-control-plaintext"
                    readOnly
                    value={row.idx}
                    onChange={(e) => setRow({ ...row, idx: e.target.value })}
                />

                <div className="bg-white border p-2 rounded mt-2 mb-5">
                    <div className="d-flex flex-row">
                        <div style={{ width: "100px" }}>
                            <ImageDropZoneDirectUpload
                                index={-1}
                                j={-1}
                                boxSize={100}
                                filename={row.filename0}
                                row={row}
                                setRow={setRow}
                            />
                            <input
                                type="text"
                                name="filename0"
                                className="form-control form-control-sm mt-1 mb-1"
                                value={row.filename0}
                                onChange={(e) => setRow({ ...row, filename0: e.target.value })}
                            />
                            <SingleFileUploader onFileUrlChange={setMainImageUrl} />
                        </div>

                        <div className="d-flex flex-column flex-fill ms-1">
                            <input
                                type="text"
                                name="title"
                                className="form-control form-control-sm"
                                placeholder="제목"
                                value={row.title}
                                onChange={(e) => setRow({ ...row, title: e.target.value })}
                                required
                            />

                            <textarea
                                name="soge"
                                className="form-control form-control-sm mt-1"
                                placeholder="소개글"
                                style={{ height: "100px" }}
                                value={row.soge}
                                onChange={(e) => setRow({ ...row, soge: e.target.value })}
                                required
                            />

                            <div className="mt-1"></div>

                            <input
                                list="categorys_option"
                                type="text"
                                name="category"
                                className="form-control form-control-sm"
                                placeholder="카테고리"
                                value={row.category}
                                onChange={(e) => setRow({ ...row, category: e.target.value })}
                            />

                            <datalist id="categorys_option">
                                {categorys.map((category, i) => (
                                    <option key={i} value={category} />
                                ))}
                            </datalist>

                            <div className="mt-1"></div>

                            <input
                                type="text"
                                name="tags"
                                className="form-control form-control-sm"
                                placeholder="태그"
                                value={row.tags}
                                onChange={(e) => setRow({ ...row, tags: e.target.value })}
                                onBlur={() => {
                                    const tags = row.tags;
                                    const newTag = replaceAll(tags, " ", "");
                                    setRow({ ...row, tags: newTag });
                                }}
                            />

                            <input
                                type="text"
                                name="writer"
                                className="form-control form-control-sm mt-1"
                                placeholder="작성자"
                                value={row.writer}
                                onChange={(e) => setRow({ ...row, writer: e.target.value })}
                            />

                            <input
                                type="text"
                                name="source"
                                className="form-control form-control-sm mt-1"
                                placeholder="출처링크"
                                value={row.source}
                                onChange={(e) => setRow({ ...row, source: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <Prepare row={row} setRow={setRow} />

                {row.steps.map((step, i) => (
                    <div key={`step_${i}`} className="mb-5">
                        <button
                            id={`step${i + 1}`}
                            type="button"
                            className="btn btn-link text-danger ps-0"
                            style={{ fontSize: "10px" }}
                            onClick={() => addRow(i, "up")}
                        >
                            <i className="bi bi-arrow-up"></i>위에 행추가
                        </button>

                        <RcpStep
                            row={row}
                            setRow={setRow}
                            step={step}
                            i={i}
                            removeRow={removeRow}
                            setAutoGen={setAutoGen}
                            generatoeMemo={generatoeMemo}
                        />

                        <button
                            type="button"
                            className="btn btn-link ps-0"
                            style={{ fontSize: "10px" }}
                            onClick={() => addRow(i, "down")}
                        >
                            <i className="bi bi-arrow-down"></i>아래에 행추가
                        </button>
                    </div>
                ))}

                <div className="d-flex justify-content-between w-100 position-fixed bottom-0 mb-2 pe-5 z-3">
                    <button type="submit" className={`btn btn-${row.idx === "" ? "primary" : "success"}`}>
                        {row.idx === "" ? "Add" : "Modify"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default RcpDetail;
