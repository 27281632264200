import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../utils/common";
import { fa } from "translate-google/languages";

const Index = () => {
    const [data, setData] = useState({});
    const [isLogined, setLogined] = useState(false);
    useEffect(() => {
        getInfo();
        const user = getUser();
        if (user.id) {
            setLogined(true);
        } else {
            setLogined(false);
        }
    }, []);

    const getInfo = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm`;
        const { data } = await axios({
            url,
            method: "GET",
        });
        setData(data);
        console.log(data);
    };

    if (!data) {
        return "no data2";
    }

    if (data.code === 0) {
        return "Q" + data.msg;
    }

    return (
        <div style={{ height: "100vh" }} className="d-flex flex-column justify-content-center">
            <div className="bg-white rounded shadow-lg p-4 d-flex flex-column align-self-center">
                <div>{data.title}</div>
                <div>MODE: {data.mode}</div>
                <div>{data.time}</div>
                <button className="btn btn-primary" onClick={getInfo}>
                    mutate
                </button>

                {isLogined ? (
                    <Link className="btn btn-link" to="/v3/adm">
                        Admin
                    </Link>
                ) : (
                    <Link className="btn btn-link" to="/v3/adm/login">
                        Login
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Index;
