import React, { useState, useEffect } from "react";
import { getAccessToken } from "../utils/common";
import axios from "axios";

function SingleFileUploader({ onFileUrlChange }) {
    const [loading, setLoading] = useState(false);

    /**
     * 클라우디나리 업로드 다이렉트로 업로드
     */
    async function handleFileChange(e) {
        setLoading(true);
        const files = e.target.files;

        const file = files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "mzbjpjdi");
        formData.append("folder", "rcp2");

        const { data } = await axios({
            url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
            method: "POST",
            data: formData,
        });

        console.log(data);

        const fileUrl = data.secure_url;

        onFileUrlChange(fileUrl);

        setLoading(false);
    }

    return (
        <>
            <input id="single_file" type="file" onChange={handleFileChange} style={{ display: "none" }} />
            {loading ? (
                <div className="d-flex lign-items-center justify-content-center">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <label
                    htmlFor="single_file"
                    className="d-flex align-items-center justify-content-center btn btn-sm btn-primary p-1"
                    style={{ fontSize: "12px", height: "24px" }}
                >
                    파일선택
                </label>
            )}
        </>
    );
}

export default SingleFileUploader;
