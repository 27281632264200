import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../components/Layout";
import DndUpload from "../components/DndUpload";

const ImageChangeTest = () => {
    const [data, setData] = useState({});
    const [filename, setFilename] = useState("");


    const getData = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm`;
        const { data } = await axios({
            url,
            method: "GET",
        });
        setData(data);
    };

    return (
        <Layout>
            <DndUpload fieldName="filename" fileUrl={filename} />
        </Layout>
    );
};

export default ImageChangeTest;
