
import axios from "axios";
import { getAccessToken } from "../utils/common";

const Prepare = ({ row, setRow }) => {

    const setAutoGen = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm/auto_gen`;
        const { data } = await axios({
            url,
            method: "POST",
            data: {
                txt: row.prepareText,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        if (data) {
            const tmp = [];
            for (const obj of data) {
                tmp.push(obj);
            }
            setRow({ ...row, prepareArr: tmp });
            console.log(row);
        }
    };

    return (
        <div className="bg-white border p-0 mt-2 mb-5">
            <h3 className="mb-0 ms-2 pt-2">Prepare(미리준비)</h3>
            <div className="d-flex flex-wrap mx-2">
                {row.prepareArr.map((item, i) => (
                    <div
                        key={i}
                        className="rounded-pill border bg-light d-flex flex-row align-items-center me-2 mt-2 pe-2"
                        style={{ fontSize: "12px" }}
                    >
                        {item.icon !== "" && (
                            <img
                                src={item.icon}
                                alt=""
                                className="rounded-pill"
                                style={{ width: "25px", height: "25px" }}
                            />
                        )}

                        <div className="d-flex flex-row" style={{ marginTop: "3px" }}>
                            <div className="ms-1 fw-bold">{item.name1}</div>
                            <div className="ms-1 fw-bold">{item.capacity}</div>
                            <div className="ms-1">{item.desc1}</div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="input-group mt-3">
                <textarea
                    className="form-control form-control-sm border-top border-0 rounded-0"
                    placeholder="내용"
                    style={{ height: "100px" }}
                    value={row.prepareText}
                    onChange={(e) => {
                        setRow({ ...row, prepareText: e.target.value });
                    }}
                />
                <button className="btn btn-primary rounded-0" type="button" onClick={() => setAutoGen()}>
                    Gen
                </button>
            </div>
        </div>
    );
};
export default Prepare;
