import { React, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formSubmit, checkAll, checkedArticleDelete } from "../utils/form";
import { getAccessToken } from "../utils/common";
import axios from "axios";
import Layout from "../components/Layout";
import Pager from "../components/Pager";
import SearchForm from "../components/SearchForm";

const Category = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? "1";
    const q = searchParams.get("q") ?? "";

    const defaultRow = { idx: "", name1: "", sort1: "" };

    const [data, setData] = useState([]);
    const [row, setRow] = useState(defaultRow);
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        getData();
    }, [page, q]);

    const getData = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm/category?page=${page}&q=${q}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        
        if (data) {
            setData(data.list);
            setPageHelper(data.pageHelper);
        }        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await formSubmit(e, null);
        console.log(result);

        getData();
        setRow(defaultRow);
        checkAll(false);
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        if (await checkedArticleDelete(e, "V3_CATEGORY_tbl")) {
            getData();
        }
        checkAll(false);
    };

    return (
        <Layout>
            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <div className="table-responsive rounded bg-white p-3 shadow">
                        <SearchForm />
                        <form onSubmit={handleDelete}>
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th className="text-center text-nowrap" width={50}>
                                            <input type="checkbox" onClick={(e) => checkAll(e.target.checked)} />
                                        </th>
                                        <th className="text-center text-nowrap" width={50}>
                                            Idx.
                                        </th>
                                        <th className="text-center text-nowrap">카테고리명</th>
                                        <th className="text-center text-nowrap">정렬순서</th>
                                    </tr>
                                    {data.map((row, i) => (
                                        <tr key={i}>
                                            <td className="text-center">
                                                <input type="checkbox" name="idx" value={row.idx} />
                                            </td>
                                            <td className="text-center">{row.idx}</td>
                                            <td className="">
                                                <a className="hand link-underline" onClick={() => setRow(row)}>
                                                    {row.name1}
                                                </a>
                                            </td>
                                            <td className="text-center">{row.sort1}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button type="submit" className="btn btn-danger btn-sm">
                                체크된 항목 삭제
                            </button>
                        </form>
                    </div>

                    <br />

                    <Pager pageHelper={pageHelper} />
                </div>
                <div className="col-12 col-md-6">
                    <div className="rounded bg-white p-3 shadow">
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="table" defaultValue={"V3_CATEGORY_tbl"} />
                            <input type="hidden" name="idx" value={row.idx} onChange={(e) => setRow({ ...row, idx: e.target.value })} />

                            <div className="d-flex flex-column mb-3">
                                <div className="d-flex flex-row">
                                    <div className="w-25 d-flex align-self-center">정렬순서</div>
                                    <div className="flex-fill">
                                        <input
                                            type="number"
                                            name="sort1"
                                            className="form-control form-control-sm"
                                            value={row.sort1}
                                            onChange={(e) => setRow({ ...row, sort1: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="d-flex flex-row mt-2">
                                    <div className="w-25 d-flex align-self-center">카테고리명</div>
                                    <div className="flex-fill">
                                        <input
                                            type="text"
                                            name="name1"
                                            className="form-control form-control-sm"
                                            value={row.name1}
                                            onChange={(e) => setRow({ ...row, name1: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-1">
                                <button type="button" className="btn btn-sm btn-danger" onClick={() => setRow(defaultRow)}>
                                    취소
                                </button>
                                <button type="submit" className="btn btn-sm btn-primary">
                                    저장
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Category;
