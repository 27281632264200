import axios from "axios";
const common = require("./common");

export const formSubmit = async (e, fileObject) => {
    //유용한놈!
    const frm = Object.fromEntries(new FormData(e.target).entries());

    if (frm.idx === "") {
        delete frm.idx;
    }

    const formData = new FormData();
    for (const key in fileObject) {
        const file = fileObject[key];
        if (file === "del") {
            console.log(key, file);
            // eval(`frm.${key} = '';`);
            frm[key] = "";
        } else {
            formData.append(`${key}`, file);
        }
    }
    if (formData) {
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/upload/s3`,
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${common.getAccessToken()}`,
            },
        });
        console.log(data);

        try {
            for (const obj of data) {
                // eval(`frm.${obj.key} = '${obj.value}';`);
                frm[obj.key] = obj.value;
            }
            console.log("frm", frm);
        } catch (error) {
            console.log(error);
        }
    }

    const { data } = await axios({
        url: `${process.env.REACT_APP_HOST}/v3crud/write`,
        method: "POST",
        data: frm,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${common.getAccessToken()}`,
        },
    });
    console.log(data);
    return data;
};

export const checkedArticleDelete = async (e, table) => {
    e.preventDefault();
    const arr = [];
    const chkList = document.querySelectorAll("input[name='idx']");
    for (const o of chkList) {
        if (o.checked) {
            if (o.value !== "") {
                arr.push(o.value);
            }
        }
    }

    console.log(arr);

    if (arr.length > 0) {
        if (window.confirm("체크된 항목을 삭제 하시겠습니까?")) {
            //배열 직열화 시키기!
            const serializedArr = arr.join(",");

            await axios({
                url: `/v3crud/delete`,
                method: "POST",
                data: {
                    table,
                    idx: serializedArr,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${common.getAccessToken()}`,
                },
            });
            return true;
        }
    }
    return false;
};

export const checkAll = (flag) => {
    const chkList = document.querySelectorAll("input[name='idx']");
    for (const o of chkList) {
        o.checked = flag;
    }
};
