import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getAccessToken } from "../utils/common";
import axios from "axios";

const DndUpload = ({ fieldName, fileUrl }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const clear = searchParams.get("clear") ?? "";

    const [isDragEnter, setIsDragEnter] = useState(false);
    const [image, setImage] = useState("/images/no-img2.png");
    const [isShow, setShowImage] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        console.log("DndUpload", fieldName, fileUrl);
        if (fileUrl) {
            setImage(fileUrl);
        } else {
            setImage("/images/no-img2.png");
        }

        if (clear === "1") {
            setImage("/images/no-img2.png");
        }
    }, [fileUrl, clear]);

    const dragEnter = (e) => {
        e.preventDefault();
        setIsDragEnter(true);
    };

    const dragLeave = (e) => {
        e.preventDefault();
        setIsDragEnter(false);
    };
    const dragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const drop = async (e) => {
        setIsDragEnter(false);
        setShowImage(false);
        setIsUploading(true);
        e.preventDefault();

        var urlLink = "";
        var file = null;
        try {
            const imageUrl = e.dataTransfer.getData("text/html");
            const rex = /src="?([^"\s]+)"?\s*/;
            const url = rex.exec(imageUrl);
            urlLink = url[1];
        } catch (e) {}

        if (urlLink) {
            var base64 = "";
            if (urlLink.includes("base64")) {
                base64 = urlLink;
            } else {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_HOST}/cloudinary/url_to_base64`,
                    method: "POST",
                    data: { url_link: urlLink },
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${getAccessToken()}`,
                    },
                });
                base64 = data.base64;
            }

            //base64 to file
            const arr = base64.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            var n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            file = new File([u8arr], "변환된파일", { type: mime });
        } else {
            // 로컬파일 업로드!!
            const files = [...e.dataTransfer.files];
            file = files[0];
        }

        console.log(file);
        const fileUrl = await cloudinaryUpload(file);

        setImage(fileUrl);
        setIsUploading(false);
    };

    /**
     * 클라우디나리 업로드 다이렉트로 업로드
     */
    const cloudinaryUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "mzbjpjdi");
        formData.append("folder", "rcp2");
        const { data } = await axios({
            url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
            method: "POST",
            data: formData,
        });
        return data.secure_url;
    };

    /**
     * 클라우디나리 업로드 서버 거쳐서 업로드
     */
    /*
    const cloudinaryUpload = async (file) => {
        const formData = new FormData();
        formData.append("upload_file", file);
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/cloudinary`,
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        return data.url;
    };
    */

    const localUpload = async (file) => {
        const formData = new FormData();
        formData.append("filename0", file);
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/upload/local`,
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        return data.filename0;
    };

    const fileRemove = () => {
        if (window.confirm("삭제 하시겠습니까?")) {
            //파일 삭제
            const segments = image.split("/");
            const filenameWithExtension = segments.pop(); // 파일명과 확장자를 함께 추출
            const filenameParts = filenameWithExtension.split("."); // 파일명을 '.' 기준으로 분리
            const extension = filenameParts.pop(); // 마지막 요소가 확장자
            const public_id = filenameParts.join("."); // 확장자를 제외한 나머지 부분이 파일명
            axios({
                url: `${process.env.REACT_APP_HOST}/cloudinary/delete_image`,
                method: "POST",
                data: {
                    public_id,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            //

            fileUrl = "";
            setImage("/images/no-img2.png");
        }
    };

    return (
        <div className="d-flex flex-column">
            <div
                className="position-relative"
                style={{ width: "100px", height: "100px" }}
                onDragEnter={(e) => dragEnter(e)}
                onDragLeave={(e) => dragLeave(e)}
                onDragOver={(e) => dragOver(e)}
                onDrop={(e) => drop(e)}
            >
                <img
                    src={image}
                    style={{ objectFit: "cover" }}
                    className={`${isDragEnter ? "bg-dark" : "bg-white"} border rounded w-100 h-100`}
                    onClick={() => {
                        if (image != "/images/no-img2.png") {
                            setShowImage(true);
                        }
                    }}
                />

                {image !== "/images/no-img2.png" && (
                    <button
                        type="button"
                        className="position-absolute btn-close btn-close-white"
                        style={{ top: "5px", right: "5px" }}
                        onClick={() => fileRemove()}
                    ></button>
                )}

                {isUploading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
            </div>

            <input
                type="text"
                style={{ width: "100px" }}
                name={fieldName}
                className="form-control form-control-sm mt-1"
                value={image == "/images/no-img2.png" ? "" : image}
                onChange={(e) => {
                    setImage(e.target.value);
                }}
            />

            {isShow && (
                <div
                    className="position-fixed w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center"
                    style={{ zIndex: "999" }}
                >
                    <img
                        src={image}
                        style={{ objectFit: "cover" }}
                        className="rounded border"
                        onClick={() => setShowImage(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default DndUpload;
