const CryptoJS = require("crypto-js");
const moment = require("moment");

export const getUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
        const user = JSON.parse(userStr);
        user.id = decrypt(user.id);
        user.idx = decrypt(user.idx);
        user.name1 = decrypt(user.name1);
        user.level1 = decrypt(user.level1);
        return user;
    }
    return null;
};

export const getSave = () => {
    const saveStr = localStorage.getItem("save");
    if (saveStr) {
        const save = JSON.parse(saveStr);
        save.id = decrypt(save.id);
        save.pw = decrypt(save.pw);
        return save;
    }
    return null;
};

export const getAccessToken = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
        return token;
    }
    return null;
};

export const getRefreshToken = () => {
    const token = localStorage.getItem("refresh_token");
    if (token) {
        return token;
    }
    return null;
};

export const removeUserSession = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

export const setUserSession = (save, user, accessToken, refreshToken) => {
    if (save.id) {
        save.id = encrypt(save.id.toString());
        save.pw = encrypt(save.pw.toString());
        localStorage.setItem("save", JSON.stringify(save));
    } else {
        localStorage.removeItem("save");
    }
    user.id = encrypt(user.id.toString());
    user.idx = encrypt(user.idx.toString());
    user.name1 = encrypt(user.name1.toString());
    user.level1 = encrypt(user.level1.toString());
    localStorage.setItem("user", JSON.stringify(user));

    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
};

export const utilConvertToMillis = (time) => {
    const dateTime = new Date(time).getTime();
        const nowTime = Math.floor(new Date().getTime());
        const date = moment(dateTime);
        const now = moment(nowTime);
        var diff = now.diff(date, "seconds");
        if (diff < 60) {
            return `방금`;
        }
        diff = now.diff(date, "minutes");
        if (diff < 60) {
            return `${diff}분 전`;
        }
        diff = now.diff(date, "hours");
        if (diff < 24) {
            return `${diff}시간 전`;
        }
        diff = now.diff(date, "days");
        if (diff < 7) {
            return `${diff}일 전`;
        }
        diff = now.diff(date, "weeks");
        if (diff < 4) {
            return `${diff}주 전`;
        }
        diff = now.diff(date, "months");
        if (diff < 12) {
            return `${diff}달 전`;
        }
        diff = now.diff(date, "years");
        return `${diff}년 전`;
};

export const replaceAll = (str, searchStr, replaceStr) => {
    if (str === "") {
        return str;
    }
    return str.split(searchStr).join(replaceStr);
};

export const getToday = () => {
    const now = new Date();
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    return `${todayYear}-${todayMonth}-${todayDate}`;
};

export const getTodayTime = () => {
    const now = new Date(); // 현재 날짜 및 시간
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[now.getDay()];
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${todayYear}년 ${todayMonth}월 ${todayDate}일 ${hours}시 ${minutes}분 ${dayOfWeek}요일`;
};

const encrypt = (text) => {
    try {
        const secretkey = "kimluabehappygoo";
        const key = CryptoJS.enc.Utf8.parse(secretkey);
        const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16));
        const cipherText = CryptoJS.AES.encrypt(`${text}`, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();
        return cipherText;
    } catch (err) {
        console.log(err);
        return "";
    }
};

const decrypt = (text) => {
    try {
        const secretkey = "kimluabehappygoo";
        const key = CryptoJS.enc.Utf8.parse(secretkey);
        const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16));
        const decrypted = CryptoJS.AES.decrypt(`${text}`, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        text = decrypted.toString(CryptoJS.enc.Utf8);
        return text;
    } catch (err) {
        console.log(err);
        return "";
    }
};
