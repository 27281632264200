import { React, useEffect, useState } from "react";
import { getAccessToken } from "../utils/common";
import { Link } from "react-router-dom";
import axios from "axios";

export default () => {
    const [isTokenOk, setTokenOk] = useState(true);

    useEffect(() => {
        (async () => {
            const { data } = await axios({
                url: `${process.env.REACT_APP_HOST}/v3adm/token_verify`,
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            console.log(data);
            setTokenOk(data);
        })();
    }, []);

    return (
        <div>
            {!isTokenOk && (
                <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
                    <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body text-center p-4">
                                <h3>토큰이 만료 되었습니다.</h3>
                                <h3>다시 로그인 해주세요.</h3>
                                <Link className="btn w-100 btn-primary" to="/v3/adm/login">
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
