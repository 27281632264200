import { React, useEffect, useRef } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { checkAll } from "../utils/form";

const SearchForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? "1";
    const q = searchParams.get("q") ?? "";

    const inputSearchEl = useRef();

    useEffect(() => {
        inputSearchEl.current.value = q;
        checkAll(false);
    }, [page, q]);

    const handelSearch = (e) => {
        e.preventDefault();
        const q = e.target.q.value;
        navigate(`${location.pathname}?page=1&q=${q}`);
        checkAll(false);
    };

    return (
        <>
            <form onSubmit={handelSearch} className="mb-3">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-4">
                        <div className="input-group">
                            <input ref={inputSearchEl} type="text" className="form-control" placeholder="검색어" name="q" />
                            <button className="btn btn-primary" type="submit">
                                검색
                            </button>
                            <button type="button" className="btn btn-outline-dark" onClick={() => navigate(`${location.pathname}?page=1&q=`)}>
                                목록
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default SearchForm;
