import { React, useEffect, useState } from "react";
import { getAccessToken } from "../utils/common";
import { useRecoilState } from "recoil";
import { loadingState } from "../utils/atom";
import axios from "axios";
import Layout from "../components/Layout";

const LastRcpData = () => {
    const [loading, setLoading] = useRecoilState(loadingState);
    const [data, setData] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const url = `${process.env.REACT_APP_HOST}/v3adm/last_rcp_data`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        // const json = data.json_data.replace(/\\/g, '');
        // console.log(data.json_data);
        setData(data.json_data);
    };

    const setIndexing = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_HOST}/v3adm/rcp_indexing`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        window.alert(JSON.stringify(data));
        setLoading(false);
    };

    return (
        <Layout>
            <textarea className="form-control" value={data} readOnly></textarea>
            <button className="btn btn-primary mt-3" onClick={setIndexing}>
                리스트 데이터 색인
            </button>
        </Layout>
    );
};

export default LastRcpData;
